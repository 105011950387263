export const CONTACT_SECTION = {
    title: 'Contact',
    subTitle: "Founders' Clinic",
    descriptionOne:
        "We're here to answer your questions and help you get started on your journey to business success.",
    descriptionTwo:
        "Contact Founders' Clinic today, and let's navigate the path to your enterprise’s  prosperity together. you may reach us always on our email ids and our whatsapp numbers.",
    contactNumber: '+919106526820',
    email: 'info@foundersclinic.com',
}
