// assets
import SliderImageOne from '../../assets/images/sliderOne.png'
import SliderImageTwo from '../../assets/images/sliderTwo.png'
import SliderImageThree from '../../assets/images/sliderThree.png'
import ImageSliderAbout from '../../assets/images/imageSlider.jpg'
import ImageSliderBlog from '../../assets/images/imageSliderBlog.png'

export const imageSliderText = [
    {
        key: 1,
        imageUrl: SliderImageOne,
        textOne: "Welcome to Founders' Clinic",
        textTwo: 'A Revolutionary B2P (Business to Professional) Platform',
        textThree:
            'Connect with skilled professionals tailored to meet the unique needs of your startup or MSME.',
    },
    {
        key: 2,
        imageUrl: SliderImageTwo,
        textOne: "Welcome to Founders' Clinic",
        textTwo: 'AI-Optimized Matchmaking of MSMEs and Professional Experts',
        textThree:
            'Connect with skilled professionals tailored to meet the unique needs of your startup or MSME.',
    },
    {
        key: 3,
        imageUrl: SliderImageThree,
        textOne: "Welcome to Founders' Clinic",
        textTwo: `An Ecosystem For Startups & MSME`,
        // textTwo:
        //     "Founders' Clinic isn't just a platform it's a catalyst for sustainable success",
        textThree:
            'Connect with skilled professionals tailored to meet the unique needs of your startup or MSME.',
    },
]

export const imageSliderAboutText = [
    {
        key: 1,
        imageUrl: ImageSliderAbout,
        blogImageUrl: ImageSliderBlog,
        textOne: 'About us',
        textTwo: `Founders' Clinic isn't just a platform it's a catalyst for sustainable success`,
        textThree:
            'Connect with skilled professionals tailored to meet the unique needs of your startup or MSME.',
        blogText: 'Resources',
    },
]
