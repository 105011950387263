import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// antd
import { Card, Col, Pagination, Row, Spin, Typography } from 'antd'

// constants
import { BLOG_PAGE } from '../../constants/blogPage'
import { PUBLIC_ROUTES } from '../../constants'

// css
import './blogPage.css'

const BlogCard = () => {
    const { Title, Text } = Typography

    const navigate = useNavigate()

    const initialState = {
        pageLimit: 10,
        currentPage: 1,
        skipPage: 0,
        blogPagesList: [],
        loading: false,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        setState({
            ...state,
            blogPagesList: BLOG_PAGE.slice(state?.skipPage, state?.pageLimit),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="blogOuterContainer">
            <Row gutter={[23, 23]}>
                {state?.blogPagesList?.length > 0 &&
                    !state?.loading &&
                    state?.blogPagesList.map((cardData) => (
                        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                            <Card
                                style={{
                                    borderRadius: '13px',
                                    border: 'none',
                                    boxShadow:
                                        '0px 4px 13px 0px rgba(0, 0, 0, 0.08)',
                                    height: '100%',
                                }}
                                bodyStyle={{
                                    height: '100%',
                                    padding: '16px',
                                }}
                                hoverable
                                onClick={() =>
                                    navigate(
                                        PUBLIC_ROUTES.blogId(
                                            cardData?.title
                                                .toLowerCase()
                                                .replace(/ /g, '-')
                                        ),
                                        {
                                            state: {
                                                data: cardData,
                                            },
                                        }
                                    )
                                }
                            >
                                <img
                                    src={cardData?.blogPicture}
                                    alt=""
                                    className="blogPageImg"
                                    height={210}
                                    width={'100%'}
                                />
                                <div className="blogInnerContainer">
                                    <Title
                                        style={{
                                            margin: '0px 0px 10px 0px',
                                        }}
                                        level={4}
                                    >
                                        {cardData?.title}
                                    </Title>
                                    <Text className="blogInnerDescription">
                                        {cardData?.description}
                                    </Text>
                                </div>
                            </Card>
                        </Col>
                    ))}
                {state?.loading && (
                    <Row
                        align={'middle'}
                        justify={'center'}
                        style={{
                            margin: '20px 0px',
                        }}
                    >
                        <Spin spinning={true} />
                    </Row>
                )}
            </Row>
            <Row
                align={'middle'}
                justify={'center'}
                style={{
                    marginTop: '60px',
                }}
            >
                <Pagination
                    defaultCurrent={1}
                    defaultPageSize={10}
                    onChange={async (value, pageSize) => {
                        setTimeout(() => {
                            setState((prevState) => ({
                                ...prevState,
                                loading: false,
                            }))
                        }, 4000)
                        setState((prevState) => ({
                            ...prevState,
                            loading: true,
                            skipPage: (value - 1) * pageSize,
                            currentPage: value,
                            blogPagesList: BLOG_PAGE.slice(
                                (value - 1) * pageSize,
                                pageSize * value
                            ),
                        }))
                    }}
                    pageSize={10}
                    total={BLOG_PAGE?.length}
                />
            </Row>
        </div>
    )
}

export default BlogCard
