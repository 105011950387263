import React from 'react'

// constants
import { imageSliderAboutText } from '../../constants/landingPage/imageSliderSection'

// css
import '../landingPage/imageSlider/imageSlider.css'

const BannerSection = ({ blogPage, blogTitle }) => {
    return (
        <div className="carousel-container">
            <div className="carousel">
                {imageSliderAboutText.map((slider, index) => (
                    <>
                        <div key={index} className={'slide'}>
                            <img
                                src={
                                    blogPage
                                        ? slider?.blogImageUrl
                                        : slider?.imageUrl
                                }
                                alt=""
                                width={'100%'}
                                height={'100%'}
                            />
                        </div>
                        <div className="sliderInner">
                            <h1 className="textCommonSlider textOneSlider">
                                {blogPage ? slider?.blogText : ''}
                            </h1>
                            <h1 className="textCommonSlider textTwoSlider">
                                {blogPage ? blogTitle : slider?.textTwo}
                            </h1>
                            <h1 className="textCommonSlider textThreeSlider">
                                {!blogPage ? slider?.textThree : ''}
                            </h1>
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}

export default BannerSection
